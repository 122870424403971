.nav-list-items li {
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.nav-list-items li::after {
  content: "";
  display: block;
  width: 0%;
  height: 1.5px;
  background: var(--color-white);
  left: 50%;
  bottom: 0;
  -webkit-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.nav-list-items li:hover::after {
  width: 100%;
}

.navbar-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  z-index: 10;
}

.navbar-overlay {
  position: fixed;
  width: 100%;
  height: 0%;
  z-index: 10;
  top: -800px;
  left: -800px;
  background: rgb(19, 19, 19);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0px 0px 100px 0px;
}

.active-nav-overlay .navbar-overlay {
  height: 100vh;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
}

.active-nav-overlay .navbar-link {
  font-size: var(--font-lg);
}

.navbar-overlay li {
  font-size: 4vw;
  color: var(--color-white);
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}

.navbar-overlay li:not(:last-child):after {
  content: "";
  display: block;
  width: 0%;
  height: 0.5vh;
  background: var(--color-light-green);
  left: 50%;
  bottom: 0;
  border-radius: 30px;
}

.navbar-overlay li:hover::after {
  width: 100%;
  background: white;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.active-nav-overlay ul li:nth-child(1) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(2) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(3) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(4) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(5) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(6) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}

.z-5 {
  z-index: 5;
}

/* HAMBURGER ICON STYLE  */
#nav-icon1 {
  width: 50px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(0.7);
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: var(--color-white);
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 16px;
}

#nav-icon1 span:nth-child(3) {
  top: 32px;
}

.active-nav-overlay #nav-icon1 {
  z-index: 20;
}

.active-nav-overlay {
  overflow: hidden;
}

.active-nav-overlay #nav-icon1 span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.active-nav-overlay #nav-icon1 span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.active-nav-overlay #nav-icon1 span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.navbar-link {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: rgba(255, 255, 255, 1);
}

.navbar-link:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
}

.navbar-link:hover {
  color: var(--color-white);
}

@media (max-width: 575.98px) {
  .navbar-overlay li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.loader-fullscreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 99;
}

.loader-fullscreen img {
  max-width: 270px;
  width: 100%;
  -webkit-animation: load-animation 3s ease infinite;
  animation: load-animation 3s ease infinite;
}

@-webkit-keyframes load-animation {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes load-animation {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ovrflw-x {
  overflow-x: hidden !important;
}

.back-to-top div {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 98;
  background: #fe0018;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-animation: jumping-animation 2s infinite;
  animation: jumping-animation 2s infinite;
}

.back-to-top svg path {
  fill: white;
}

@-webkit-keyframes jumping-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jumping-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* ============================== MODALS ============================== */
.modal-backdrop {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal-body {
  padding: 0 !important;
}

.mw-451 {
  max-width: 451px;
}

.modal-fullscreen .modal-header {
  border: 0;
}

.modal-para {
  max-width: 348px;
}

.modal-content {
  background-image: url("./assets/img/modal-bg.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.modal-footer {
  border: 0 !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.btn-close {
  background-image: url("./assets/img/cards/cross.png") !important;
  background-position: center;
  background-size: contain !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 15px !important;
  margin-right: 10px !important;
  opacity: 1 !important;
}

.modal-header {
  padding: 10px 20px 0 0 !important;
}

/* ============================== FOOTER ============================== */
.join-us:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  box-shadow: 0px 5px 15px #211337, 0px -5px 15px #211337;
  top: 0;
}

.join-us h2 {
  line-height: 0.8;
}

.z-2 {
  z-index: 2;
}

.footer span {
  line-height: 1;
}

.footer {
  background-image: url("./assets/img/footer-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.footer-btn {
  transition: all 0.5s ease-in-out !important;
  background-color: #7b61ff;
  border: 2px solid white;
  border-radius: 58px;
  padding: 12px 50px;
  font-weight: 700;
  color: white;
}

.footer-btn:hover {
  box-shadow: inset 200px 0px 0px white;
  color: #7b61ff;
}

.left-img {
  max-width: 498px;
  top: -247px;
  z-index: 1;
}

.right-img {
  max-width: 492px;
  top: -178px;
  z-index: 1;
  right: 0;
}

.social-icons svg {
  transition: all 0.3s ease-in-out;
}

.social-icons svg:hover {
  transform: translateY(-5px);
}

@media (min-width: 768px) {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 1399.98px) {
  .right-img {
    max-width: 372px;
    top: -81px;
  }

  .left-img {
    max-width: 372px;
    top: -130px;
  }

  .join-us:after {
    box-shadow: 0px 0px 0px transparent, 0px -17px 11px black;
    height: 10px;
    top: 11px;
  }
}

@media (max-width: 1199.98px) {
  .right-img {
    max-width: 319px;
  }

  .left-img {
    max-width: 319px;
  }
}

@media (max-width: 991.98px) {
  .right-img {
    max-width: 227px;
    top: -36px;
  }

  .left-img {
    max-width: 227px;
    top: -62px;
  }

  .footer {
    background-size: cover;
  }

  .footer h2 {
    line-height: 1;
  }

  .footer-logo {
    max-width: 270px;
  }

  .modal-header {
    padding: 0px 0px 0 0 !important;
  }

  .btn-close {
    width: 10px !important;
    height: 10px !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
  }
}

@media (max-width: 767.98px) {
  .right-img {
    max-width: 200px;
    top: 1px;
  }

  .left-img {
    max-width: 200px;
    top: -23px;
  }

  .footer-btn {
    padding: 9px 35px;
  }

  .footer-logo {
    max-width: 200px !important;
  }
}

@media (max-width: 575.98px) {
  .right-img {
    max-width: 150px;
    top: 45px;
  }

  .left-img {
    max-width: 150px;
    top: 25px;
  }

  .footer-btn {
    padding: 7px 25px;
  }

  .footer-logo {
    max-width: 180px !important;
  }

  .btn-close {
    width: 10px !important;
    height: 10px !important;
  }
}

@media (max-width: 450px) {
  .right-img,
  .left-img {
    top: 90px;
    max-width: 130px;
  }
}
