:root {
  /*================================== FONT-SIZES ================================================ */
  --font-sm: 16px;
  --font-24: 24px;
  --font-md: 32px;
  --font-lg: 36px;
  --font-xl: 48px;
  --font-xxl: 96px;
  /*================================== COLOR-CLASS ================================================ */
  --color-white: #fafafa;
  --color-black: #000000;
  --color-blue: rgba(123, 97, 255, 1);
}

/*====================================== Media-queries-start ======================================== */
@media (max-width: 1198.98px) {
  :root {
    --font-sm: 15px;
    --font-md: 28px;
    --font-lg: 30px;
    --font-xl: 40px;
    --font-xxl: 65px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font-xxl: 55px;
    --font-24: 20px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --font-sm: 15px;
    --font-md: 28px;
    --font-24: 18px;
    --font-lg: 30px;
    --font-xl: 35px;
    --font-xxl: 48px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 25px;
    --font-lg: 28px;
    --font-xl: 35px;
    --font-xxl: 35px;
  }
}

@media (max-width: 399.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 25px;
    --font-lg: 28px;
    --font-xl: 32px;
    --font-xxl: 35px;
  }
}

body {
  font-family: "Amiri", serif;
  background-color: var(--color-black);
  color: var(--color-white);
}

/*====================================== FONT-SIZE CLASS START ======================================= */
.font-sm {
  font-size: var(--font-sm) !important;
}
.font-24 {
  font-size: var(--font-24);
}
.font-md {
  font-size: var(--font-md) !important;
}

.font-lg {
  font-size: var(--font-lg) !important;
}

.font-xl {
  font-size: var(--font-xl) !important;
}

.font-xxl {
  font-size: var(--font-xxl) !important;
}

.ff-cinzel {
  font-family: "Cinzel", serif;
}

/*====================================== COLOR CLASS START =================================================== */
.text-black {
  color: var(--color-black) !important;
}

.text-blue {
  color: var(--color-blue) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-blue {
  background-color: var(--color-blue) !important;
}

.fw-700 {
  font-weight: 700;
}

.main-heading {
  font-size: var(--font-xl);
  font-weight: 900;
}

.heading {
  font-size: var(--font-lg);
  font-weight: 700;
  color: var(--color-secondary);
}

.sub-heading {
  font-size: var(--font-md);
  font-weight: 600;
  color: var(--color-success);
}

.small-heading {
  font-size: var(--font-sm);
  font-weight: 600;
  color: var(--color-danger);
}

.normal-para {
  font-size: var(--font-sm);
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.blue-btn {
  font-size: var(--font-sm);
  font-weight: 700;
  color: var(--color-white);
  background: var(--color-blue);
  border: 2px solid var(--color-white);
  border-radius: 54px;
  padding: 10px 60px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blue-btn:hover {
  background-color: var(--color-white);
  border: 2px solid var(--color-blue);
  color: #7b61ff;
}

.cursor-pointer {
  cursor: pointer;
}

/* ================================== COMMON CLASS END ================================================== */
.hero_section {
  background: url("./assets/img/hero-bg.webp");
  background-repeat: no-repeat;
  background-position: bottom;
  /* min-height: 100vh; */
  background-size: cover;
}

.hero_section_content {
  min-height: calc(100vh - 65px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.commin_soon {
  max-width: 370px;
}

.heading-img {
  max-width: 65px;
  margin-top: -10px;
}
.now-live-hover {
  transition: all 0.2s ease-in;
}
.now-live-hover:hover {
  transform: translateY(-5px);
}
/*============================================= MODREN WORLD CSS =============================================*/
.modren_world {
  background-color: #f40044;
  background-image: url("./assets/img/world-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*============================================= EVERYONE  CSS =============================================*/
.not_everyone {
  background-color: #000000;
  background-image: url("./assets/img/bg-img-3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.shadow-img {
  position: absolute;
  width: 100%;
  right: 0%;
  top: 0%;
  z-index: -1;
}

/*=============================================  HOW TO PLAY CSS =============================================*/
.how_to_play {
  background-image: url("./assets/img/bg-img-4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #f40044;
}

.round_circle {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: var(--font-lg);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}

.fill_blue_on_hover {
  cursor: pointer;
}

.fill_blue_on_hover:hover .round_circle {
  background-color: var(--color-blue);
  border: 1px solid var(--color-blue);
}

/*=============================================  TEAM CSS =============================================*/
.team_section {
  background-image: url("./assets/img/team-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.team_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid transparent;
  overflow: hidden;
}

.team_card:hover {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid var(--color-blue);
}

.team_card img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.team_card:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.tab-btn-active {
  background-color: #fe0018 !important;
}

.team_card h2 {
  font-size: var(--font-md);
  font-weight: 700;
}

.team_card:hover h2 {
  color: var(--color-blue);
}

.mw-164 {
  max-width: 164px;
}
.mw-265 {
  max-width: 265px;
}
.mw-390 {
  max-width: 300px;
}

.team_img_container img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 150px;
  width: 150px !important;
  -o-object-position: top;
  object-position: top;
}
.cards_hero {
  background-image: url("./assets/img/cards/hero-bg-cards.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.cards_input {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 65px;
  border: 2px solid rgba(123, 97, 255, 1);
  padding: 18px 27px;
  width: 100%;
  color: rgba(255, 255, 255, 1);
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: var(--font-sm);
  font-weight: 400;
}
.cards_input:focus {
  outline: none;
}
.cards_input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 1);
}
.cards_input::-moz-placeholder {
  color: rgba(255, 255, 255, 1);
}
.cards_input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 1);
}
.cards_input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 1);
}
.cards_input::placeholder {
  color: rgba(255, 255, 255, 1);
}
.cards_button {
  font-size: var(--font-sm);
  font-weight: 700;
  color: rgba(255, 255, 255, 0.899);
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.901);
  border-radius: 54px;
  padding: 10px 32px;
}
.cards_button:hover {
  -webkit-box-shadow: inset 200px 0px 0px #fe0018;
  box-shadow: inset 200px 0px 0px #fe0018;
}

.footer-form-select {
  background-color: transparent !important;
  min-width: 150px;
  cursor: pointer !important;
  max-width: 200px;
}
.css-1s2u09g-control {
  background-color: transparent !important;
  border-radius: 60px !important;
  border: solid rgba(255, 255, 255, 0.899);
  font-size: var(--font-sm) !important;
  font-weight: 700;
  padding: 4px 10px;
  border-width: 2px !important;
  cursor: pointer !important;
}
.css-6j8wv5-Input {
  color: rgba(255, 255, 255, 0.899) !important;
  cursor: pointer !important;
}
.css-qc6sy-sins-6j8wv5-Input {
  color: rgba(255, 255, 255, 0.899) !important;
  cursor: pointer !important;
}
.css-qc6sy-singleValue {
  color: rgba(255, 255, 255, 0.899) !important;
  cursor: pointer !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
  cursor: pointer !important;
}
.css-yt9ioa-option {
  background-color: transparent !important;
  color: white !important;
  cursor: pointer !important;
}
.css-1n7v3ny-option {
  background-color: #7b61ff69 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}
.css-9gakcf-option {
  background-color: var(--color-blue) !important;
  border-radius: 4px !important;
}
.css-b62m3t-container {
  background-color: transparent !important;
}
.css-tlfecz-indicatorContainer:hover {
  color: white !important;
}
.css-1gtu0rj-indicatorContainer:hover {
  color: white !important;
}
.css-1pahdxg-control {
  background-color: transparent !important;
  border-radius: 60px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid rgba(255, 255, 255, 0.899) !important;
  padding: 4px 10px;
  outline: none !important;
  font-weight: 700;
}
.css-4ljt47-MenuList {
  background-color: #000000 !important;
  border: 1px solid var(--color-white) !important;
  border-radius: 5px !important;
}
.scroll_overflow {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
}
.translate_hover {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.translate_hover:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.demon_img {
  -webkit-animation: scale 9s ease-in-out infinite;
  animation: scale 9s ease-in-out infinite;
}
.shadow-img {
  -webkit-animation: scale_reverse 9s linear infinite;
  animation: scale_reverse 9s linear infinite;
}
.how_to_play_img {
  -webkit-animation: rotate_animation 2.5s 0.3s ease-in infinite;
  animation: rotate_animation 2.5s 0.3s ease-in infinite;
}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@-webkit-keyframes scale_reverse {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes scale_reverse {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@-webkit-keyframes rotate_animation {
  0% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  10% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  20% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  30% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  80% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  90% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
}
@keyframes rotate_animation {
  0% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  10% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  20% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  30% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  80% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  90% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(-5deg) scale(0.9);
    transform: rotate(-5deg) scale(0.9);
  }
}
/*======================================= HOMEPAGE CARDS ANIMATION =======================================*/
.cards_animation {
  background: url("./assets/img/cards/card-1.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: cards_animationn 5s infinite;
  animation: cards_animationn 5s infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  width: 350px;
  height: 450px;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
}
.cards_animation2 {
  background: url("./assets/img/cards/card-2.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: cards_animationn2 5s infinite;
  animation: cards_animationn2 5s infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  width: 350px;
  height: 450px;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
}
.cards_rotation_animation {
  width: 350px;
  position: relative;
  height: 450px;
  opacity: 1;
}
.card_change_animation {
  -webkit-animation: img_change 20s ease-in-out infinite;
  animation: img_change 20s ease-in-out infinite;
  width: 350px;
  height: 450px;
}
.card_change_animation2 {
  -webkit-animation: img_change2 25s infinite;
  animation: img_change2 25s infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  width: 350px;
  height: 450px;
}
@-webkit-keyframes img_change {
  0% {
    background: url("./assets/img/cards/card-2.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  15% {
    background: url("./assets/img/cards/card-2.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  25% {
    background: url("./assets/img/cards/card-3.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  40% {
    background: url("./assets/img/cards/card-3.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  50% {
    background: url("./assets/img/cards/card-4.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  65% {
    background: url("./assets/img/cards/card-4.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  75% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  90% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  100% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@keyframes img_change {
  0% {
    background: url("./assets/img/cards/card-2.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  15% {
    background: url("./assets/img/cards/card-2.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  25% {
    background: url("./assets/img/cards/card-3.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  40% {
    background: url("./assets/img/cards/card-3.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  50% {
    background: url("./assets/img/cards/card-4.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  65% {
    background: url("./assets/img/cards/card-4.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  75% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  90% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  100% {
    background: url("./assets/img/cards/card-5.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@-webkit-keyframes img_change2 {
  0% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  10% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  20% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  30% {
    background: url("./assets/img/cards/card-8.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  40% {
    background: url("./assets/img/cards/card-8.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  50% {
    background: url("./assets/img/cards/card-9.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  60% {
    background: url("./assets/img/cards/card-9.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  70% {
    background: url("./assets/img/cards/card-10.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  80% {
    background: url("./assets/img/cards/card-10.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  90% {
    background: url("./assets/img/cards/card-11.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  100% {
    background: url("./assets/img/cards/card-11.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@keyframes img_change2 {
  0% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  10% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  20% {
    background: url("./assets/img/cards/card-7.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  30% {
    background: url("./assets/img/cards/card-8.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  40% {
    background: url("./assets/img/cards/card-8.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  50% {
    background: url("./assets/img/cards/card-9.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  60% {
    background: url("./assets/img/cards/card-9.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  70% {
    background: url("./assets/img/cards/card-10.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  80% {
    background: url("./assets/img/cards/card-10.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  90% {
    background: url("./assets/img/cards/card-11.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  100% {
    background: url("./assets/img/cards/card-11.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@-webkit-keyframes cards_animationn {
  0% {
    -webkit-transform: translateX(-100px) rotate(0deg) scale(1);
    transform: translateX(-100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  20% {
    -webkit-transform: translateX(-100px) rotate(0deg) scale(1);
    transform: translateX(-100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  40% {
    -webkit-transform: translateX(-50px) rotate(5deg) scale(0.9);
    transform: translateX(-50px) rotate(5deg) scale(0.9);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(-50px) rotate(5deg) scale(0.9);
    transform: translateX(-50px) rotate(5deg) scale(0.9);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateY(-40px) rotate(20deg) scale(0.9);
    transform: translateY(-40px) rotate(20deg) scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(20deg) scale(0.9);
    transform: translateY(-40px) rotate(20deg) scale(0.9);
    opacity: 0;
  }
}
@keyframes cards_animationn {
  0% {
    -webkit-transform: translateX(-100px) rotate(0deg) scale(1);
    transform: translateX(-100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  20% {
    -webkit-transform: translateX(-100px) rotate(0deg) scale(1);
    transform: translateX(-100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  40% {
    -webkit-transform: translateX(-50px) rotate(5deg) scale(0.9);
    transform: translateX(-50px) rotate(5deg) scale(0.9);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(-50px) rotate(5deg) scale(0.9);
    transform: translateX(-50px) rotate(5deg) scale(0.9);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateY(-40px) rotate(20deg) scale(0.9);
    transform: translateY(-40px) rotate(20deg) scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(20deg) scale(0.9);
    transform: translateY(-40px) rotate(20deg) scale(0.9);
    opacity: 0;
  }
}
@-webkit-keyframes cards_animationn2 {
  0% {
    -webkit-transform: translateX(100px) rotate(0deg) scale(1);
    transform: translateX(100px) rotate(0deg) scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateX(100px) rotate(0deg) scale(1);
    transform: translateX(100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  40% {
    -webkit-transform: translateX(50px) rotate(-5deg) scale(0.9);
    transform: translateX(50px) rotate(-5deg) scale(0.9);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(50px) rotate(-5deg) scale(0.9);
    transform: translateX(50px) rotate(-5deg) scale(0.9);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-40px) rotate(-20deg) scale(0.9);
    transform: translateY(-40px) rotate(-20deg) scale(0.9);
    opacity: 0;
  }
}
@keyframes cards_animationn2 {
  0% {
    -webkit-transform: translateX(100px) rotate(0deg) scale(1);
    transform: translateX(100px) rotate(0deg) scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateX(100px) rotate(0deg) scale(1);
    transform: translateX(100px) rotate(0deg) scale(1);
    opacity: 0;
  }

  40% {
    -webkit-transform: translateX(50px) rotate(-5deg) scale(0.9);
    transform: translateX(50px) rotate(-5deg) scale(0.9);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(50px) rotate(-5deg) scale(0.9);
    transform: translateX(50px) rotate(-5deg) scale(0.9);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-40px) rotate(-20deg) scale(0.9);
    transform: translateY(-40px) rotate(-20deg) scale(0.9);
    opacity: 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-text-svg {
    width: 300px;
  }
}

@media (max-width: 991.98px) {
  .hero-text-svg {
    width: 200px;
  }

  .loader-fullscreen img {
    max-width: 200px;
  }

  .commin_soon {
    max-width: 250px;
  }
  .cards_input {
    padding: 10px 17px;
  }
  .mw-390 {
    max-width: 200px;
  }
}

@media (max-width: 767.98px) {
  .loader-fullscreen img {
    max-width: 150px;
  }
}

@media (max-width: 575.98px) {
  .w-xs-100 {
    width: 100%;
  }
  .commin_soon {
    max-width: 200px;
  }

  .heading-img {
    max-width: 50px;
    margin-top: -10px;
  }

  .round_circle {
    width: 50px;
    height: 50px;
  }

  .team_card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }
  .cards_button {
    padding: 7px 25px;
  }
  .css-1s2u09g-control {
    padding: 0 10px !important;
  }
  .cards_animation,
  .cards_rotation_animation,
  .cards_animation2,
  .card_change_animation2,
  .card_change_animation {
    width: 250px;
    height: 330px;
  }
}
.modal-content {
  border-radius: 18px;
}
.modal {
  transition: all 0.5s ease-in;
}
.Scrollbar_modal ::-webkit-scrollbar-thumb,
.Scrollbar_modal ::-webkit-scrollbar-track,
.Scrollbar_modal ::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.Scrollbar_modal ::-webkit-scrollbar-thumb {
  width: 2px;
  height: 5px;
}
@media (min-width: 1600px) {
  .team_section {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .how_to_play,
  .not_everyone,
  .not_everyone,
  .modren_world {
    background-size: 100% 100%;
  }
  .mw-390 {
    max-width: 390px;
  }
}

@media (min-width: 1200px) {
  .lh-28 {
    line-height: 28px;
  }
  .lh-20 {
    line-height: 20px;
  }
  .mw-265 {
    max-width: 355px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .container-modified {
    max-width: 1140px;
    margin: 0 auto;
  }
}
.css-11unzgr,
.css-26l3qy-menu {
  background-color: #000000 !important;
  border-radius: 5px !important;
}

.css-26l3qy-menu {
  background-color: #000000 !important;
  border: 1px solid white !important;
}
